/* Global Styles */
html, body {
    width: 100%;
    height: 100%;
    font-family: 'Verdana', 'Calibri', 'Lucida Grande', sans-serif;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #5a5a5a;
    background: #fff;
  }
  
  body {
    overflow-x: hidden;
  }
  
  .signature-editor {
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
  
  .form-section {
    width: 300px;
  }
  
  .bloco-input {
    margin-bottom: 10px;
  }
  
  .bloco-input label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .bloco-input input {
    width: 90%;
    font-family: 'Tahoma', 'Calibri', 'Lucida Grande', sans-serif;
    height: 20px;
    line-height: 20px;  
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .bordawhere {
    border: 1px solid #d2d2d2;
    width: 600px;  
    padding: 20px;
  }
  
  .whereto {  
    padding-left: 0;
  }
  
  .icone-inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
  }